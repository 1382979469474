export const STATIC_ROUTES = {
  REPORTS: '/reports',
  REPORTS_REPORT_BUILDER: '/reports/custom',
  REPORTS_STATISTICS: '/reports/statistics',
  CORPORATE_LIBRARY: '/corporate-library',
  // CNA
  CNA: '/cna',
  CNA_ASSIGNMENTS: '/cna/assignments',
  CNA_DEFAULT_TASKS: '/cna/default-tasks',
  TASKS: '/tasks',
  SCHEDULE: '/schedule',
  BEDBOARD: '/bedboard',
};
