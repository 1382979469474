import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import _ from 'lodash';
import { useAuth } from '../../../../../container/authContext';
import ResidentModal from '../../../../../components/company/resident-modal';
import { extractBedParts } from '../../../../../utility/index';
import { editContacts } from '../../../../../services/contacts';
import { moveResidentService } from '../../../../../services/bedboard';
import './styles.scss';
import cx from 'classnames';
import { Tooltip } from 'react-tooltip';
import { isResponseOk } from '../../../../../utility/isResponseOk';
import { isTablet } from 'react-device-detect';

const UnitBedRow = ({ unitBed, getNotes, setBedboardData, facility, unit, lastRoomNumber, isPrint }) => {
  const { user } = useAuth();

  const { letter, number } = extractBedParts(unitBed.bed);
  const [addResidentData, setAddResidentData] = useState(null);

  const handleCreateResident = (resident) => {
    setBedboardData((prevState) => {
      const newState = _.cloneDeep(prevState);

      newState.units.forEach((u) => {
        if (resident.unit.id == u.id) {
          u.beds.forEach((b) => {
            if (b.id == resident.unit_bed.id) {
              b.resident = {
                id: resident.id,
                first_name: resident.first_name,
                last_name: resident.last_name,
                note: '',
              };
            }
          });

          u.in_house_count = u.beds.filter((item) => item.resident && item.resident?.note !== 'H').length;
        }
      });

      newState.total_in_house = _.sum(newState.units.map((u) => u.in_house_count));
      newState.total_with_bed_holds = _.sum(newState.units.map((u) => u.beds.filter((item) => !!item.resident).length));

      return newState;
    });

    setAddResidentData(null);
  };

  const handleResidentCodeChange = async (bed, note) => {
    try {
      const body = {
        note,
      };

      const response = await editContacts(body, bed.resident.id);

      if (response) {
        const responseResidentData = {
          id: response.id,
          first_name: response.first_name,
          last_name: response.last_name,
          note: response.note,
        };

        setBedboardData((prevState) => {
          const newState = _.cloneDeep(prevState);
          newState.units.forEach((u) => {
            if (u.id == unit.id) {
              u.beds.forEach((b) => {
                if (b.resident?.id == bed.resident?.id) {
                  b.resident = responseResidentData;
                }
              });

              u.in_house_count = u.beds.filter((item) => item.resident && item.resident?.note !== 'H').length;
            }
          });

          newState.total_in_house = _.sum(newState.units.map((u) => u.in_house_count));
          return newState;
        });

        getNotes();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [collected, drag] = useDrag(
    () => ({
      type: 'unit_bed',
      item: { unitBed: { ...unitBed, unit_id: unit.id } },
      canDrag: !!unitBed.resident && !isPrint && !isTablet,
      end: async (item, monitor) => {
        try {
          const dropResult = monitor.getDropResult();

          if (dropResult?.unitBed) {
            if (dropResult.unitBed.id !== item.unitBed?.id) {
              const dragUnitBed = item.unitBed;
              const dropUnitBed = dropResult?.unitBed;

              const residentData = _.clone(dragUnitBed.resident);

              const body = {
                from_bed: dragUnitBed.id,
                to_bed: dropUnitBed.id,
                resident: dragUnitBed.resident?.id,
              };

              const response = await moveResidentService(body);

              if (isResponseOk(response)) {
                setBedboardData((prevState) => {
                  const newState = _.cloneDeep(prevState);

                  newState.units.forEach((u) => {
                    if (u.id == dragUnitBed.unit_id) {
                      u.in_house_count += residentData.note === 'H' ? 0 : -1;
                      u.beds.forEach((b) => {
                        if (b.id == dragUnitBed.id) {
                          b.resident = null;
                        }
                      });
                    }

                    if (u.id == dropUnitBed.unit_id) {
                      u.in_house_count += residentData.note === 'H' ? 0 : 1;
                      u.beds.forEach((b) => {
                        if (b.id == dropUnitBed.id) {
                          b.resident = residentData;
                        }
                      });
                    }
                  });
                  return newState;
                });
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [unitBed.resident],
  );

  const [, drop] = useDrop(
    () => ({
      accept: ['unit_bed'],
      canDrop: () => !unitBed.resident && !isPrint && !isTablet,
      drop: () => ({ unitBed: { ...unitBed, unit_id: unit.id } }),
    }),
    [unitBed.resident],
  );

  return (
    <>
      <div
        ref={unitBed.resident ? drag : drop}
        className={cx('tr cursor-pointer', {
          'is-first-room-bed': lastRoomNumber !== number,
        })}
        style={{ opacity: collected.isDragging ? 0.5 : 1 }}
        onClick={
          !unitBed.resident && !isPrint && !isTablet
            ? () => {
                setAddResidentData({ facility, unit: unit, unit_bed: unitBed });
              }
            : () => {}
        }
      >
        <div className="td room-col">
          <Tooltip opacity={1} id={`${unitBed.bed}-room-tooltip`} content={number} />
          <span data-tooltip-id={`${unitBed.bed}-room-tooltip`}>{number}</span>
        </div>
        <div className="td bed-col">{letter}</div>
        <div className="td resident-col" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {unitBed.resident?.first_name} {unitBed.resident?.last_name}
        </div>
        <div className="td code-col">
          {!!unitBed.resident?.id && (
            <input
              type="text"
              readOnly={isTablet}
              className="code-input"
              value={unitBed.resident?.note || ''}
              onChange={(e) => handleResidentCodeChange(unitBed, e.target.value)}
            />
          )}
        </div>
      </div>
      {addResidentData && (
        <ResidentModal
          isOpen
          companyId={user.company?.id}
          closeModal={() => setAddResidentData(null)}
          handelCreateUser={handleCreateResident}
          facilities={[facility]}
          userData={addResidentData}
        />
      )}
    </>
  );
};

export default UnitBedRow;
