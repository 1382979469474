// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notes-modal .modal-body .add-note-btn {
  height: 32px;
}
.notes-modal .notes-table {
  margin-top: 16px;
}
.notes-modal .notes-table .th {
  font-size: 12px;
  padding: 13px 10px;
}
.notes-modal .notes-table .td {
  font-size: 14px;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/bedboard/components/NotesModal/styles.scss"],"names":[],"mappings":"AAEI;EACE,YAAA;AADN;AAKE;EACE,gBAAA;AAHJ;AAKI;EACE,eAAA;EACA,kBAAA;AAHN;AAMI;EACE,eAAA;EACA,aAAA;AAJN","sourcesContent":[".notes-modal {\n  .modal-body {\n    .add-note-btn {\n      height: 32px;\n    }\n  }\n\n  .notes-table {\n    margin-top: 16px;\n\n    .th {\n      font-size: 12px;\n      padding: 13px 10px;\n    }\n\n    .td {\n      font-size: 14px;\n      padding: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
