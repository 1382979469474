// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-unit-btn {
  padding: 5px 12px;
  height: unset;
}

.unit-actions-btn {
  width: unset !important;
  height: 41px !important;
  padding: 10px 18px !important;
}
.unit-actions-btn svg {
  stroke: #101828;
}
.unit-actions-btn svg path {
  fill: #101828;
  stroke: #101828;
}

.unit-actions-menu {
  padding: 16px 10px;
}

.unit-beds-table .tr:hover .unit-bed-actions-btn {
  visibility: visible;
}
.unit-beds-table .tr.room-start .td {
  border-top: 1px solid #98a2b3;
}
.unit-beds-table .tr .unit-bed-actions-btn {
  visibility: hidden;
  transition: 0ms;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.unit-beds-table .tr .td-action {
  text-align: right;
}
.unit-beds-table .tr .resident-name {
  color: #101828;
}`, "",{"version":3,"sources":["webpack://./src/pages/units/UnitDetailPage/styles.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;AACF;;AAEA;EACE,uBAAA;EACA,uBAAA;EACA,6BAAA;AACF;AACE;EAKE,eAAA;AAHJ;AADI;EACE,aAAA;EACA,eAAA;AAGN;;AAGA;EACE,kBAAA;AAAF;;AAMM;EACE,mBAAA;AAHR;AAQM;EACE,6BAAA;AANR;AAUI;EACE,kBAAA;EACA,eAAA;EACA,YAAA;EACA,6BAAA;EACA,gBAAA;AARN;AAWI;EACE,iBAAA;AATN;AAWI;EACE,cAAA;AATN","sourcesContent":[".delete-unit-btn {\n  padding: 5px 12px;\n  height: unset;\n}\n\n.unit-actions-btn {\n  width: unset !important;\n  height: 41px !important;\n  padding: 10px 18px !important;\n\n  svg {\n    path {\n      fill: #101828;\n      stroke: #101828;\n    }\n    stroke: #101828;\n  }\n}\n\n.unit-actions-menu {\n  padding: 16px 10px;\n}\n\n.unit-beds-table {\n  .tr {\n    &:hover {\n      .unit-bed-actions-btn {\n        visibility: visible;\n      }\n    }\n\n    &.room-start {\n      .td {\n        border-top: 1px solid #98a2b3;\n      }\n    }\n\n    .unit-bed-actions-btn {\n      visibility: hidden;\n      transition: 0ms;\n      border: none;\n      background-color: transparent;\n      box-shadow: none;\n    }\n\n    .td-action {\n      text-align: right;\n    }\n    .resident-name {\n      color: #101828;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
