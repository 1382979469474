import client from '../api';
import axios from 'axios';
import qs from 'qs';

const CancelToken = axios.CancelToken;
let cancel;

export const getUnitBedsService = async (unit_id, params) => {
  if (cancel !== undefined) {
    cancel();
  }

  return await client.get(`api/units/${unit_id}/beds`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};

export const createUnitBedService = async (unit_id, data) => {
  if (cancel !== undefined) {
    cancel();
  }

  return await client.post(`api/units/${unit_id}/beds/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};

export const updateUnitBedService = async (unit_id, bed_id, data) => {
  if (cancel !== undefined) {
    cancel();
  }

  return await client.patch(`api/units/${unit_id}/beds/${bed_id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};

export const deleteUnitBedService = async (unit_id, bed_id) => {
  if (cancel !== undefined) {
    cancel();
  }

  return await client.delete(`api/units/${unit_id}/beds/${bed_id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};
