import React from 'react';

export const cnaColors = [
  {
    bg: '#FFECB8',
    border: '#7A6D1A',
  },
  {
    bg: '#CFFCEF',
    border: '#2B9462',
  },
  {
    bg: '#FFF9A6',
    border: '#7A6D1A',
  },
  {
    bg: '#CFF5FF',
    border: '#05A1D6',
  },
  {
    bg: '#FFBFE0',
    border: '#B00066',
  },
  {
    bg: '#FFCCC9',
    border: '#AB0000',
  },
  {
    bg: '#C7D6FF',
    border: '#2B489C',
  },
  {
    bg: '#EED6D6',
    border: '#181F29',
  },
  {
    bg: '#D4E9B8',
    border: '#566727',
  },
  {
    bg: '#F2D7BE',
    border: '#7A4A1A',
  },
];

export const tableColumns = [
  {
    header: 'CNA',
    accessorKey: 'cna',
    cellClassName: 'cna',
  },
  {
    header: 'Room',
    accessorKey: 'room',
    cellClassName: 'room',
  },
  {
    header: 'Resident',
    accessorKey: 'resident',
    cellClassName: 'resident',
  },
  {
    header: 'Tasks',
    accessorKey: 'tasks',
    cellClassName: 'tasks',
  },
];

export const shiftOptions = [
  {
    label: '7-3',
    value: '7_3',
  },
  {
    label: '3-11',
    value: '3_11',
  },
  {
    label: '11-7',
    value: '11_7',
  },
];

export const periodOptions = [
  {
    label: 'Days in a week',
    value: 'week_days',
  },
  {
    label: 'Custom dates',
    value: 'custom_dates',
  },
  {
    label: 'Custom interval',
    value: 'custom_interval',
  },
];

export const weekDaysOptions = [
  {
    label: 'Sunday',
    value: 0,
  },
  {
    label: 'Monday',
    value: 1,
  },
  {
    label: 'Tuesday',
    value: 2,
  },
  {
    label: 'Wednesday',
    value: 3,
  },
  {
    label: 'Thursday',
    value: 4,
  },
  {
    label: 'Friday',
    value: 5,
  },
  {
    label: 'Saturday',
    value: 6,
  },
];

export const quantityOptions = [
  {
    label: 'every',
    value: 1,
  },
  {
    label: 'every 2nd',
    value: 2,
  },
  {
    label: 'every 3rd',
    value: 3,
  },
  {
    label: 'every 4th',
    value: 4,
  },
  {
    label: 'every 5th',
    value: 5,
  },
];
