import client from '../api';
import axios from 'axios';
import qs from 'qs';

const CancelToken = axios.CancelToken;
let cancel;
let cancelUserGroup;
export const getCompanyUnitsList = async (id, params) => {
  if (cancelUserGroup !== undefined) {
    cancelUserGroup();
  }

  const response = await client.get(`api/companies/${id}/units`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUserGroup = c;
    }),
  });

  return response.data;
};
export const createUnit = async (data) => {
  const response = await client.post('api/units/bulk-create/', data);

  return response.data;
};
export const editUnit = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/units/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const deactivateUnit = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`api/units/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const activateUnit = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/units/${id}/restore/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getUnitListSettings = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`api/corporate-library/forms/settings/units/`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const updateUnitCredentialsService = async (data, unitID) => {
  if (cancel !== undefined) {
    cancel();
  }
  return await client.patch(`/api/units/${unitID}/credentials/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};

export const getUnitService = async (unitID) => {
  if (cancel !== undefined) {
    cancel();
  }
  return await client.get(`/api/units/${unitID}/?status=all`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};

export const getUnitResidentsService = async (unitID) => {
  if (cancel !== undefined) {
    cancel();
  }
  return await client.get(`/api/units/${unitID}/residents`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};
