import React, { useState, useMemo } from 'react';
import Modal from 'react-modal';
import _ from 'lodash';
import { createNoteService, updateNoteService } from '../../../../../services/bedboard';
import { isResponseOk } from '../../../../../utility/isResponseOk';

const ManageNotesModal = ({ data, onClose, facilityID, setNotes }) => {
  const isEditing = !!data?.id;

  const [formData, setFormData] = useState({
    name: isEditing ? data.name : '',
    code: isEditing ? data.code : '',
  });
  const [errors, setErrors] = useState({});

  const isValidForm = useMemo(() => {
    return formData.name.trim().length > 0 && formData.code.trim().length > 0;
  }, [JSON.stringify(formData)]);

  const handleSubmit = async () => {
    try {
      const params = {
        facility_id: facilityID,
      };

      const body = {
        name: formData.name,
        code: formData.code,
      };

      if (isEditing) {
        const response = await updateNoteService(data.id, params, body);

        if (isResponseOk(response)) {
          setNotes((state) => {
            const newState = _.cloneDeep(state);

            newState.map((item) => {
              if (item.id == data.id) {
                item.name = response.data.name;
                item.code = response.data.code;
              }
              return item;
            });

            return newState;
          });

          onClose();
        }
      } else {
        const response = await createNoteService(params, body);

        if (isResponseOk(response)) {
          setNotes((state) => [...state, response.data]);
          onClose();
        } else {
          setErrors(response.data);
        }
      }
    } catch (e) {
      if (e.response?.data) {
        setErrors(e.response.data);
      }
      console.log(e);
    }
  };

  return (
    <Modal
      isOpen
      className="modal-default manage-notes-modal"
      overlayClassName="modal-overlay"
      onRequestClose={onClose}
      contentLabel="Manage Notes Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        {isEditing ? 'Edit' : 'Add'} Note
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      <div className="modal-body">
        <div className="form-wrapper">
          <div className="form-item">
            <label className="form-label">Note</label>
            <input
              id="name"
              value={formData.name}
              onChange={(e) => setFormData((state) => ({ ...state, name: e.target.value }))}
              type="text"
              className="form-control"
              placeholder="Enter"
            />
          </div>
          <div className="form-item form-item-half">
            <label className="form-label">Code</label>
            <input
              id="code"
              value={formData.code}
              onChange={(e) => setFormData((state) => ({ ...state, code: e.target.value }))}
              type="text"
              className="form-control"
              placeholder="Enter"
            />
            <span className="form-error">{errors.code && errors.code[0]}</span>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" disabled={!isValidForm} onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default ManageNotesModal;
