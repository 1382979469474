import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import './styles.scss';
import { createUnitBedService, updateUnitBedService } from '../../../../services/unit-beds';
import { getUnitResidentsService } from '../../../../services/units';
import { isResponseOk } from '../../../../utility/isResponseOk';
import { extractBedParts } from '../../../../utility';
import Select from 'react-select';

const ManageUnitBedModal = ({ data, unit, onClose, onSuccess }) => {
  const isEditing = !!data?.id;
  const initResidentOption = data?.resident
    ? { value: data.resident.id, label: `${data.resident.first_name} ${data.resident.last_name}` }
    : null;
  const [formData, setFormData] = useState({
    room: extractBedParts(data.bed).number,
    bed: extractBedParts(data.bed).letter,
    resident: initResidentOption,
  });
  const [errors, setErrors] = useState({});
  const [residentOptions, setResidentOptions] = useState([]);

  const handleAddBed = async () => {
    try {
      const body = {
        bed: `${formData.room.trim()}${formData.bed.trim()}`,
        unit: unit.id,
        resident: formData.resident ? formData.resident.value : null,
      };
      const response = await createUnitBedService(unit.id, body);

      if (isResponseOk(response)) {
        onClose();
        onSuccess();
      }
    } catch (e) {
      if (e.response.data) {
        setErrors(e.response.data);
      }
      console.error(e);
    }
  };

  const handleUpdateBed = async () => {
    try {
      const body = {
        bed: `${formData.room.trim()}${formData.bed.trim()}`,
        unit: unit.id,
        resident: formData.resident ? formData.resident.value : null,
      };
      const response = await updateUnitBedService(unit.id, data.id, body);

      if (isResponseOk(response)) {
        onClose();
        onSuccess();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isValidForm = useMemo(() => {
    return formData.room?.trim() && formData.bed?.trim();
  }, [formData]);

  useEffect(() => {
    getUnitResidentsService(unit.id)
      .then((response) => {
        setResidentOptions(response.data.map((item) => ({ value: item.id, label: `${item.first_name} ${item.last_name}` })));
      })
      .catch((e) => {
        console.log(e);
      });
  }, [unit, data]);

  return (
    <Modal
      isOpen
      className="modal-default add-new-bed-modal"
      overlayClassName="modal-overlay cna-add-task-modal-overlay"
      onRequestClose={onClose}
      contentLabel="Add new bed modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        {isEditing ? 'Edit' : 'Add New Bed'}
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      {!!unit.name && !isEditing && <h3 className="modal-subheader">for {unit.name}</h3>}

      <div className="modal-body">
        <div className="form-wrapper">
          <div className="form-item form-item-half">
            <label className="form-label">Room Number</label>
            <input
              placeholder="Enter"
              value={formData.room}
              onChange={(e) => setFormData((state) => ({ ...state, room: e.target.value }))}
              type="text"
              className="form-control"
            />
          </div>
          <div className="form-item form-item-half">
            <label className="form-label">Bed</label>
            <input
              placeholder="Enter"
              value={formData.bed}
              onChange={(e) => setFormData((state) => ({ ...state, bed: e.target.value }))}
              type="text"
              className="form-control"
            />
            <span className="form-error">{errors.bed && errors.bed[0] && errors.bed[0]}</span>
          </div>
          {isEditing && (
            <div className="form-item form-item-select">
              <label className="form-label">Resident Name</label>
              <Select
                options={residentOptions}
                className="form-control-select"
                classNamePrefix="react-select"
                value={formData.resident}
                placeholder="Not selected"
                onChange={(e) => setFormData((state) => ({ ...state, resident: e }))}
              ></Select>
              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" disabled={!isValidForm} onClick={isEditing ? handleUpdateBed : handleAddBed}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default ManageUnitBedModal;
