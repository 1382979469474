import React, { useState } from 'react';
import Modal from 'react-modal';
import ContextMenu from '../../../../components/other/context-menu';
import { deleteNoteService } from '../../../../services/bedboard';
import { isResponseOk } from '../../../../utility/isResponseOk';
import DeleteNoteModal from './DeleteNoteModal';
import ManageNotesModal from './ManageNotesModal';
import './styles.scss';
import { isTablet, isMobile } from 'react-device-detect';

const NotesModal = ({ notes, setIsNotesModalOpen, getBedboardData, getNotes, facilityID, setNotes }) => {
  const [manageNoteModalData, setManageNoteModalData] = useState(null);
  const [deleteNoteID, setDeleteNoteID] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  const handleDeleteNote = async () => {
    try {
      const params = {
        facility_id: facilityID,
      };

      const response = await deleteNoteService(deleteNoteID, params);

      if (isResponseOk(response)) {
        setNotes((state) => state.filter((item) => item.id !== deleteNoteID));
        setDeleteNoteID(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="modal-default notes-modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setIsNotesModalOpen(false)}
        contentLabel="Notes Modal"
        ariaHideApp={false}
      >
        <h2 className="modal-header">
          Notes
          <button
            className="btn btn-icon"
            onClick={() => {
              setIsNotesModalOpen(false);
            }}
          >
            <i className="icon-icon-x" />
          </button>
        </h2>
        <div className="modal-body">
          {!isMobile && !isTablet && (
            <button
              className="btn btn-outline d-flex align-center add-note-btn"
              onClick={() => {
                setIsOpen(false);
                setManageNoteModalData({});
              }}
            >
              <svg className="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1V15M1 8H15" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Add Note
            </button>
          )}
          <div className="table-responsive table-default table-full notes-table">
            <div className="table-wrapper">
              <div className="table">
                <div className="tr">
                  <div className="th">Name</div>
                  <div className="th">Code</div>
                  <div className="th">Amount</div>
                  {!isTablet && !isMobile && <div className="th"></div>}
                </div>
                {notes.map((item) => {
                  return (
                    <div className="tr" key={item.id}>
                      <div className="td">{item.name}</div>
                      <div className="td">{item.code}</div>
                      <div className="td">{item.residents_count}</div>
                      {!isMobile && !isTablet && (
                        <div className="td td-actions">
                          {item.code !== 'H' && (
                            <ContextMenu
                              size="small"
                              menu={[
                                {
                                  label: 'Edit Note',
                                  icon: <i className="icon-icon-edit" />,
                                  action: ({ onCloseMenu }) => {
                                    setManageNoteModalData(item);
                                    setIsOpen(false);

                                    onCloseMenu();
                                  },
                                },
                                {
                                  label: 'Delete Note',
                                  color: '#B32318',
                                  icon: <i className="icon-remove" />,
                                  action: ({ onCloseMenu }) => {
                                    setDeleteNoteID(item.id);
                                    onCloseMenu();
                                  },
                                },
                              ]}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-outline" onClick={() => setIsNotesModalOpen(false)}>
            Cancel
          </button>
          <button className="btn" onClick={() => setIsNotesModalOpen(false)}>
            Save
          </button>
        </div>
      </Modal>
      {!!manageNoteModalData && (
        <ManageNotesModal
          data={manageNoteModalData}
          onClose={() => {
            setManageNoteModalData(null);
            setIsOpen(true);
          }}
          onSuccess={getNotes}
          facilityID={facilityID}
          setNotes={setNotes}
        />
      )}
      {!!deleteNoteID && <DeleteNoteModal closeModal={() => setDeleteNoteID(null)} handleConfirm={handleDeleteNote} />}
    </>
  );
};

export default NotesModal;
