import React from 'react';
import Modal from 'react-modal';
import { deleteUnitBedService } from '../../../../services/unit-beds';
import './styles.scss';
import { isResponseOk } from '../../../../utility/isResponseOk';

const DeleteUnitBedModal = ({ closeModal, onSuccess, unitBed }) => {
  const handleClose = (e) => {
    e.stopPropagation();
    closeModal();
  };

  const handleConfirm = async () => {
    try {
      const response = await deleteUnitBedService(unitBed.unit, unitBed.id);

      if (isResponseOk(response)) {
        onSuccess();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      className="modal-alert delete-task-modal"
      isOpen
      onRequestClose={handleClose}
      contentLabel="Delete Unit Bed Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        <img src="/images/alert-icon.svg" alt="" />
        <button className="btn btn-icon" onClick={handleClose}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div>
        <h3>Confirm bed deletion</h3>
        <p>This action is irreversible and will permanently remove all associated entities. Deleted information cannot be restored.</p>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handleClose}>
          Cancel
        </button>
        <button
          className="btn btn-danger"
          onClick={(e) => {
            e.stopPropagation();
            handleConfirm();
          }}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default DeleteUnitBedModal;
