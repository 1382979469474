import client from '../api';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancel;

export const getAvailableFacilitiesService = async (params) => {
  return await client.get(`api/bedboard/facilities/`, {
    params,
  });
};

export const getBedboardDataService = async (params) => {
  return await client.get('api/bedboard/', {
    params,
  });
};

export const getNotesService = async (params) => {
  return await client.get('api/bedboard/notes/', {
    params,
  });
};

export const createNoteService = async (params, body) => {
  if (cancel !== undefined) {
    cancel();
  }

  return await client.post('api/bedboard/notes/', body, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};

export const updateNoteService = async (noteID, params, body) => {
  if (cancel !== undefined) {
    cancel();
  }

  return await client.patch(`api/bedboard/notes/${noteID}/`, body, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};

export const deleteNoteService = async (noteID, params) => {
  if (cancel !== undefined) {
    cancel();
  }

  return await client.delete(`api/bedboard/notes/${noteID}/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};

export const moveResidentService = async (body) => {
  if (cancel !== undefined) {
    cancel();
  }

  return await client.post('api/bedboard/move-resident/', body, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};
