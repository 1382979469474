import React, { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';
import './styles.scss';
import cx from 'classnames';
import useOutsideClick from './hooks/useOutsideClick';
import ReactDOM from 'react-dom';

// size - small | big
const ContextMenu = ({ size = 'big', styles, menu = [], btnClassName, menuClass }) => {
  const menuButtonWrapperRef = useRef(null);
  const menuRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });

  const handleToggleMenu = (_isOpen) => {
    setIsOpen((prevState) => {
      return typeof _isOpen === 'boolean' ? _isOpen : !prevState;
    });
  };

  const closeMenu = useCallback(() => handleToggleMenu(false), []);

  useOutsideClick(
    [menuButtonWrapperRef, menuRef],
    () => {
      closeMenu();
    },
    isOpen,
  );

  useEffect(() => {
    if (isOpen && menuButtonWrapperRef.current && menuRef.current) {
      const menuButton = menuButtonWrapperRef.current;
      const menuButtonRect = menuButton.getBoundingClientRect();
      const menuButtonBottom = menuButtonRect.bottom;
      const screenRight = window.innerWidth;

      const newTop = menuButtonBottom + 4;
      let newLeft = menuButtonRect.left;

      const menuWidth = menuRef.current.getBoundingClientRect().width;

      if (newLeft + menuWidth > screenRight) {
        newLeft = menuButtonRect.right - menuWidth;
      }

      newLeft = Math.max(newLeft, 0);

      setMenuPosition({ left: newLeft, top: newTop });
    }
  }, [isOpen]);

  const renderMenu = () => {
    if (!isOpen) return null;

    const layout = (
      <div
        ref={menuRef}
        className={`context-btn-menu ${menuClass}`}
        style={{ left: menuPosition.left, top: menuPosition.top }}
        onClick={(event) => event.stopPropagation()}
      >
        {menu.map((item, index) => {
          const { render, label, color, icon, action, disabled } = item;
          const ctxData = { ...item, index, onCloseMenu: closeMenu };
          let layout;

          if (render) {
            layout = render(ctxData);
          } else {
            layout = (
              <div className={cx('item', { disabled: disabled })} style={{ color }} data-color={color} onClick={() => action(ctxData)}>
                {icon}
                <span>{label}</span>
              </div>
            );
          }

          return <Fragment key={index}>{layout}</Fragment>;
        })}
      </div>
    );

    return ReactDOM.createPortal(layout, document.body);
  };

  return (
    <>
      <button
        style={styles}
        ref={menuButtonWrapperRef}
        className={cx(`btn btn btn-outline context-menu-btn ${btnClassName}`, { small: size === 'small', big: size === 'big' })}
        onClick={handleToggleMenu}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M9.99984 10.832C10.4601 10.832 10.8332 10.4589 10.8332 9.9987C10.8332 9.53846 10.4601 9.16536 9.99984 9.16536C9.5396 9.16536 9.1665 9.53846 9.1665 9.9987C9.1665 10.4589 9.5396 10.832 9.99984 10.832Z"
            fill="#667085"
          />
          <path
            d="M9.99984 4.9987C10.4601 4.9987 10.8332 4.6256 10.8332 4.16536C10.8332 3.70513 10.4601 3.33203 9.99984 3.33203C9.5396 3.33203 9.1665 3.70513 9.1665 4.16536C9.1665 4.6256 9.5396 4.9987 9.99984 4.9987Z"
            fill="#667085"
          />
          <path
            d="M9.99984 16.6654C10.4601 16.6654 10.8332 16.2923 10.8332 15.832C10.8332 15.3718 10.4601 14.9987 9.99984 14.9987C9.5396 14.9987 9.1665 15.3718 9.1665 15.832C9.1665 16.2923 9.5396 16.6654 9.99984 16.6654Z"
            fill="#667085"
          />
          <path
            d="M9.99984 10.832C10.4601 10.832 10.8332 10.4589 10.8332 9.9987C10.8332 9.53846 10.4601 9.16536 9.99984 9.16536C9.5396 9.16536 9.1665 9.53846 9.1665 9.9987C9.1665 10.4589 9.5396 10.832 9.99984 10.832Z"
            stroke="#667085"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.99984 4.9987C10.4601 4.9987 10.8332 4.6256 10.8332 4.16536C10.8332 3.70513 10.4601 3.33203 9.99984 3.33203C9.5396 3.33203 9.1665 3.70513 9.1665 4.16536C9.1665 4.6256 9.5396 4.9987 9.99984 4.9987Z"
            stroke="#667085"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.99984 16.6654C10.4601 16.6654 10.8332 16.2923 10.8332 15.832C10.8332 15.3718 10.4601 14.9987 9.99984 14.9987C9.5396 14.9987 9.1665 15.3718 9.1665 15.832C9.1665 16.2923 9.5396 16.6654 9.99984 16.6654Z"
            stroke="#667085"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        {renderMenu()}
      </button>
    </>
  );
};

export default memo(ContextMenu);
