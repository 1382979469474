import cx from 'classnames';
import React, { memo, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import { useAuth } from '../../../../../../container/authContext';
import { assignCNATasksToShiftRoomService } from '../../../../../../services/cna/assignments/cna';
import { getCNADefaultTasksService } from '../../../../../../services/cna/default-tasks';
import { isResponseOk } from '../../../../../../utility/isResponseOk';
import { periodOptions, quantityOptions, shiftOptions, weekDaysOptions } from '../../constants';
import MultiDatePicker, { DateObject } from 'react-multi-date-picker';

// import CustomDatesPicker from '../../../../../../components/library/custom-dates-picker';
import './styles.scss';

const AddTasksModal = ({ addTasksModalData, onClose, onRefetchAssignment }) => {
  const { user } = useAuth();
  const companyID = user?.company?.id;
  const { shiftRecordID } = addTasksModalData;

  const [formData, setFormData] = useState({
    selectedTasks: [],
    isPeriodic: false,
    targetType: null,
    selectedShifts: [],
    period: null,
    selectedWeekDays: [],
    selectedCustomDates: [],
    repeatQuantity: null,
    repeatDay: null,
  });

  const [errors, setErrors] = useState({});
  const [defaultTasksSuggest, setDefaultTasksSuggest] = useState([]);
  const [taskInputValue, setTaskInputValue] = useState('');

  const options = useMemo(() => {
    return defaultTasksSuggest?.map(({ id, text, ...rest }) => ({ value: id, label: text, ...rest }));
  }, [defaultTasksSuggest]);

  const selectedOptions = useMemo(() => {
    return formData.selectedTasks.reduce((acc, { id, text, ...rest }) => {
      const taskOption = {
        value: id,
        label: text,
        ...rest,
      };
      acc.push(taskOption);
      return acc;
    }, []);
  }, [formData.selectedTasks]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getCNADefaultTasksService({ companyID });
        if (isResponseOk(res) && res.data) {
          setDefaultTasksSuggest(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [companyID]);

  const handleRemoveTask = (value) => {
    setFormData((prevState) => ({ ...prevState, selectedTasks: prevState.selectedTasks.filter(({ id }) => String(id) !== String(value)) }));
  };

  const handleChangeTask = (data) => {
    const newSelectedTasks = data.map(({ value, label, ...rest }) => ({ id: value, text: label, ...rest }));
    setFormData((state) => ({ ...state, selectedTasks: newSelectedTasks }));
  };

  const handleTaskSelectBlur = () => {
    const formattedNewTaskName = taskInputValue.trim();
    if (formattedNewTaskName.length) {
      const newTask = { id: new Date().getTime(), text: formattedNewTaskName, isCustom: true };
      setFormData((state) => ({ ...state, selectedTasks: [...state.selectedTasks, newTask] }));
      setTaskInputValue('');
    }
  };

  const handleTaskInputChange = (value) => setTaskInputValue(value);

  const handleTaskSelectKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleTaskSelectBlur();
    }
  };

  const handleSave = async () => {
    try {
      let newTasks = formData.selectedTasks.map((task) => {
        let taskData = {};
        if (task.isCustom) {
          taskData.name = task.text;
        } else {
          taskData.default_task_id = task.id;
        }
        return taskData;
      });

      const data = {
        shiftRecordID,
        body: {
          tasks: newTasks,
        },
      };

      if (formData.isPeriodic) {
        const periodSettings = {};
        periodSettings.target_type = formData.targetType;
        periodSettings.period_type = formData.period?.value;
        periodSettings.shift_types = formData.selectedShifts.map((item) => item.value);

        switch (formData.period?.value) {
          case 'week_days': {
            periodSettings.selected_weekdays = formData.selectedWeekDays.map((item) => item.value);
            break;
          }
          case 'custom_dates': {
            periodSettings.custom_dates = formData.selectedCustomDates.map((item) => item.day);
            break;
          }
          case 'custom_interval': {
            periodSettings.repeat_quantity = formData.repeatQuantity?.value;
            periodSettings.repeat_day = formData.repeatDay?.value;
            break;
          }
        }

        data.body.period_settings = periodSettings;
      }

      const response = await assignCNATasksToShiftRoomService(data);
      if (isResponseOk(response)) {
        onClose();
        onRefetchAssignment();
      } else {
        setErrors(response.response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      isOpen
      className={cx('modal-default add-tasks-modal')}
      overlayClassName={'modal-overlay add-tasks-modal-overlay'}
      onRequestClose={onClose}
      contentLabel="Add Tasks Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        Add Tasks
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      <div className="form-wrapper">
        <div className="form-item">
          <span className={'select-label'}>Tasks</span>
          <Select
            isMulti
            hideSelectedOptions
            inputValue={taskInputValue}
            placeholder="Enter or select tasks"
            value={selectedOptions}
            options={options}
            closeMenuOnSelect={false}
            className="form-control-select"
            classNamePrefix="react-select-multi"
            onChange={handleChangeTask}
            onBlur={handleTaskSelectBlur}
            onKeyDown={handleTaskSelectKeyDown}
            onInputChange={handleTaskInputChange}
          />
          <span className="form-error">{errors?.tasks && errors.tasks[0]}</span>
          <div className="selected-tasks-list">
            {selectedOptions.map(({ value, label: text }) => {
              return (
                <div key={value} className={'selected-tasks-item'}>
                  <p>{text}</p>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => handleRemoveTask(value)}
                  >
                    <path
                      d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                      stroke="#FDA29B"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              );
            })}
          </div>
        </div>
        <div className="form-item">
          <label className="form-label">Repeat {selectedOptions.length > 1 ? 'these tasks' : 'this task'} in the future</label>
          <div className="d-flex is-periodic-radio-group form-radio-group">
            <div className="form-control-radio">
              <input
                type="radio"
                name="is_periodic"
                onChange={(e) => setFormData((state) => ({ ...state, isPeriodic: true }))}
                checked={formData.isPeriodic}
              />
              <span>Yes</span>
            </div>
            <div className="form-control-radio">
              <input
                type="radio"
                name="is_periodic"
                onChange={(e) => setFormData((state) => ({ ...state, isPeriodic: false }))}
                checked={!formData.isPeriodic}
              />
              <span>No</span>
            </div>
          </div>
        </div>
        {!!formData.isPeriodic && (
          <>
            <div className="form-item">
              <label className="form-label">Choose the type</label>
              <div className="d-flex period-type-wrapper form-radio-group">
                <div className="form-control-radio">
                  <input
                    type="radio"
                    name="target_type"
                    onChange={(e) => setFormData((state) => ({ ...state, targetType: 'bed' }))}
                    checked={formData.targetType === 'bed'}
                  />
                  <span>
                    for bed
                    <i className="icon-icon-info ml-2" data-tooltip-id="for-bed-tooltip"></i>
                    <Tooltip opacity={1} id="for-bed-tooltip" place="bottom">
                      If the task is scheduled for the bed, then regardless of the resident these tasks will appear with the set period for
                      this bed.
                    </Tooltip>
                  </span>
                </div>
                <div className="form-control-radio">
                  <input
                    type="radio"
                    name="target_type"
                    onChange={(e) => setFormData((state) => ({ ...state, targetType: 'resident' }))}
                    checked={formData.targetType === 'resident'}
                  />
                  <span>
                    for the current resident
                    <i className="icon-icon-info ml-2" data-tooltip-id="for-resident-tooltip"></i>
                    <Tooltip opacity={1} id="for-resident-tooltip" place="bottom">
                      If the task is scheduled for the bed, then regardless of the resident these tasks will appear with the set period for
                      this bed.
                    </Tooltip>
                  </span>
                </div>
              </div>
              <span className="form-error">{errors?.period_settings?.target_type && errors.period_settings.target_type[0]}</span>
            </div>
            <div className="form-item">
              <span className="select-label select-shifts-label">Shifts</span>
              <Select
                isMulti
                isSearchable={false}
                placeholder="Select shifts"
                value={formData.selectedShifts}
                hideSelectedOptions={false}
                options={shiftOptions}
                closeMenuOnSelect={false}
                className="form-control-select"
                classNamePrefix="react-select-multi"
                onChange={(value) => setFormData((state) => ({ ...state, selectedShifts: value }))}
              />
              <span className="form-error">{errors?.period_settings?.shift_types && errors.period_settings.shift_types[0]}</span>
              <div className="selected-shifts-list">
                {formData.selectedShifts.map(({ value, label: text }) => {
                  return (
                    <div key={value} className="selected-shift-item">
                      <p>{text}</p>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() =>
                          setFormData((state) => ({
                            ...state,
                            selectedShifts: state.selectedShifts.filter((item) => item.value !== value),
                          }))
                        }
                      >
                        <path
                          d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                          stroke="#FDA29B"
                          strokeWidth="1.67"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="form-item">
              <span className="select-label">Period</span>
              <Select
                isSearchable={false}
                placeholder="Select"
                value={formData.period}
                options={periodOptions}
                className="form-control-select"
                classNamePrefix="react-select"
                onChange={(value) => setFormData((state) => ({ ...state, period: value }))}
              />
              <span className="form-error">{errors?.period_settings?.period_type && errors.period_settings.period_type[0]}</span>
            </div>
            {formData.period?.value === 'week_days' && (
              <div className="form-item">
                <span className="select-label select-shifts-label">Select days</span>
                <Select
                  isMulti
                  isSearchable={false}
                  hideSelectedOptions={false}
                  placeholder="Name of the day"
                  value={formData.selectedWeekDays}
                  options={weekDaysOptions}
                  closeMenuOnSelect={false}
                  className="form-control-select"
                  classNamePrefix="react-select-multi"
                  onChange={(value) => setFormData((state) => ({ ...state, selectedWeekDays: value }))}
                />
                <span className="form-error">
                  {errors?.period_settings?.selected_weekdays && errors.period_settings.selected_weekdays[0]}
                </span>
                <div className="selected-weekdays-list">
                  {formData.selectedWeekDays.map(({ value, label: text }) => {
                    return (
                      <div key={value} className="selected-weekdays-item">
                        <p>{text}</p>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() =>
                            setFormData((state) => ({
                              ...state,
                              selectedWeekDays: state.selectedWeekDays.filter((item) => item.value !== value),
                            }))
                          }
                        >
                          <path
                            d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                            stroke="#FDA29B"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {formData.period?.value == 'custom_dates' && (
              // <div className="form-item">
              //   <label className="form-label">Custom dates every month</label>
              //   <CustomDatesPicker
              //     value={formData.selectedCustomDates}
              //     onChange={(value) => setFormData((state) => ({ ...state, selectedCustomDates: value }))}
              //   />
              //   <span className="form-error">{errors?.period_settings?.custom_dates && errors.period_settings.custom_dates[0]}</span>
              // </div>
              <div className={'form-item multi-date-picker disabled-change-month mb-4'}>
                <div className={'clear-datepicker'}>
                  <MultiDatePicker
                    multiple
                    format={'DD'}
                    editable={false}
                    placeholder={'Select dates'}
                    currentDate={
                      new DateObject({
                        year: 2024,
                        month: 12,
                        day: 1,
                      })
                    }
                    value={formData.selectedCustomDates}
                    onChange={(dates) => setFormData((state) => ({ ...state, selectedCustomDates: dates.map((value) => value) }))}
                  />
                  {formData.selectedCustomDates.length > 0 && (
                    <button onClick={() => setFormData((state) => ({ ...state, selectedCustomDates: [] }))} className={'clear'}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clipPath="url(#clip0_5205_22763)">
                          <path
                            d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                            stroke="#D92D20"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5205_22763">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  )}
                </div>
                <span className="form-error">{errors?.period_settings?.custom_dates && errors.period_settings.custom_dates[0]}</span>
              </div>
            )}
            {formData.period?.value === 'custom_interval' && (
              <>
                <div className="form-item form-item-half">
                  <span className="select-label">Repeat</span>
                  <Select
                    isSearchable={false}
                    placeholder="Select quantity"
                    value={formData.repeatQuantity}
                    options={quantityOptions}
                    className="form-control-select"
                    classNamePrefix="react-select"
                    onChange={(value) => setFormData((state) => ({ ...state, repeatQuantity: value }))}
                  />
                  <span className="form-error">{errors?.period_settings?.repeat_day && errors.period_settings.repeat_day[0]}</span>
                </div>
                <div className="form-item form-item-half">
                  <span className="select-label"></span>
                  <Select
                    isSearchable={false}
                    placeholder="Select quantity"
                    value={formData.repeatDay}
                    options={weekDaysOptions}
                    className="form-control-select"
                    classNamePrefix="react-select"
                    onChange={(value) => setFormData((state) => ({ ...state, repeatDay: value }))}
                  />
                  <span className="form-error">
                    {errors?.period_settings?.repeat_quantity && errors.period_settings.repeat_quantity[0]}
                  </span>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" onClick={handleSave}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default memo(AddTasksModal);
