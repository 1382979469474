// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-task-modal .modal-header {
  margin-bottom: 20px;
}
.delete-task-modal .modal-footer {
  padding-top: 0;
  margin-top: 56px;
}`, "",{"version":3,"sources":["webpack://./src/pages/cna/assignments/unit/components/delete-task-modal/styles.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAGE;EACE,cAAA;EACA,gBAAA;AADJ","sourcesContent":[".delete-task-modal {\n  .modal-header {\n    margin-bottom: 20px;\n  }\n\n  .modal-footer {\n    padding-top: 0;\n    margin-top: 56px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
