import { stateUSA } from '../constants/state';

export const Initials = (str) => {
  return str
    .split(/\s+/)
    .map((w) => w.substring(0, 1).toUpperCase())
    .join('');
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const getState = (stateId) => {
  if (stateId.length > 2) {
    return stateUSA.find((item) => item.label === stateId);
  } else {
    return stateUSA.find((item) => item.value === stateId);
  }
};

export const redirectToLibrary = (navigate) => {
  navigate('/corporate-library');
};

export const getAllDaysInMonth = (month, year) =>
  Array.from({ length: new Date(year, month, 0).getDate() }, (_, i) => new Date(year, month - 1, i + 1));

export const extractBedParts = (input) => {
  if (!input) return { number: '', letter: '' };
  const match = input.match(/(\d+)?([A-Za-z])?/);
  return {
    number: match?.[1] || null,
    letter: match?.[2] || null,
  };
};
