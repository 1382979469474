import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EmptyPage } from '../other/EmptyPage';
import { Tooltip } from 'react-tooltip';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import useOutsideClick from '../../hook/useOutsideClick';
import { dateToISOString } from '../../utility/dates/dateToISOString';

function UserTab({
  users,
  openImport,
  openAddModal,
  totalPage,
  handlePageClick,
  onUpdate,
  totalUser,
  handelFilter,
  filtersUser,
  facilities,
  units,
}) {
  const { id } = useParams();
  const [usersList, setUsersList] = useState(users);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState((prevState) => {
    const newState = filtersUser;
    if (filtersUser) {
      newState['status'] = { value: 'active', label: 'Active' };
    }

    return newState;
  });
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const [facilitiesOption, setFacilitiesOption] = useState([]);
  const [unitsOption, setUnitsOption] = useState([]);
  const navigate = useNavigate();
  const [column, setColumn] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef();
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useOutsideClick(ref, closeDropdown);

  const handleDateChange = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  useEffect(() => {
    if (endDate) {
      setFilters({
        ...filters,
        date_added_before: endDate,
        date_added_after: startDate,
      });
    } else {
      setFilters({
        ...filters,
        date_added_before: null,
        date_added_after: null,
      });
    }
    // eslint-disable-next-line
  }, [endDate, startDate]);

  useEffect(() => {
    setColumn([
      {
        name: 'Name',
        total: totalUser,
      },
      {
        name: 'Phone Number',
      },
      {
        name: 'Facility',
      },
      {
        name: 'Unit',
      },
      {
        name: 'Room Number',
      },
    ]);
    // eslint-disable-next-line
  }, [search, users]);
  useEffect(() => {
    let filteredData = users;
    if (search) {
      filteredData = users.filter(
        (item) =>
          `${item.first_name} ${item.last_name}`.toLowerCase().includes(search.toLowerCase()) ||
          `${item.last_name} ${item.first_name}`.toLowerCase().includes(search.toLowerCase()),
      );
    }
    setUsersList(filteredData);
  }, [search, users]);

  useEffect(() => {
    if (facilities) {
      setFacilitiesOption(facilities.map((item) => ({ value: item.id, label: item.name })));
    }
  }, [facilities]);

  useEffect(() => {
    if (units) {
      setUnitsOption(units.map((item) => ({ value: item.id, label: item.name })));
    }
  }, [units]);

  useEffect(() => {
    if (filtersUser) {
      const newFilter = { ...filters };
      for (let key in newFilter) {
        if (typeof newFilter[key] === 'object' && !Array.isArray(newFilter[key]) && newFilter[key] !== null) {
          if (newFilter[key].value) {
            newFilter[key] = newFilter[key].value;
          } else {
            newFilter[key] = dateToISOString(newFilter[key]);
          }
        }

        if (Array.isArray(newFilter[key])) {
          newFilter[key] = newFilter[key].map((item) => item.value);
        }
      }

      handelFilter(newFilter);
    }

    // eslint-disable-next-line
  }, [filters]);

  const onFilter = (value, key) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };
  const clearFilter = () => {
    setFilters({
      facilities_ids: [],
      units_ids: [],
      date_added_before: '',
      date_added_after: '',
      status: { value: 'active', label: 'Active' },
      search: '',
    });

    setStartDate(null);
    setEndDate(null);
  };
  // const handleActivateUser = (id) => {
  //     activateUser(id).then(res => onUpdate()).catch(e => console.log(e))
  // }
  //
  // const handleDeactivateUser = (id) => {
  //     deactivateUser(id).then(res => onUpdate()).catch(e => console.log(e))
  // }

  return (
    <>
      <div className="library-filter">
        {!filtersUser && (
          <div className="library-filter-search form-control-item">
            <input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
            <i className="icon-search"></i>
          </div>
        )}
        {filtersUser && (
          <>
            <div className="library-filter-search form-control-item">
              <input
                type="text"
                className="form-control"
                value={filters.search}
                onChange={(e) => onFilter(e.target.value, 'search')}
                placeholder="Search"
              />
              <i className="icon-search"></i>
            </div>
            <div>
              <button className="btn btn-outline" onClick={() => setFiltersIsOpen(!filtersIsOpen)}>
                <i className="icon-icon-sliders-rotate"></i>
              </button>
            </div>
          </>
        )}

        <div>
          <div className="dropdown" ref={ref}>
            <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn">
              <i className="icon-plus-svgrepo-com mr-2"></i> Add Resident
            </button>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <button className="btn ml-0 btn-left btn-full btn-text" onClick={openAddModal}>
                    <i className="icon-plus-svgrepo-com mr-2"></i> Add manually
                  </button>
                </li>
                <li>
                  <button className={'btn ml-0 btn-full btn-text'} onClick={() => openImport(true)}>
                    <i className="icon-file-text mr-2"></i> Excel File import
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      {filtersIsOpen && (
        <div className="library-filters">
          <div className="library-filters-wrapper">
            <div className="form-item">
              <label className="form-label">Facility</label>
              <Select
                isMulti
                hideSelectedOptions={false}
                options={facilitiesOption}
                value={filters.facilities_ids}
                onChange={(e) => onFilter(e, 'facilities_ids')}
                className="form-control-select"
                classNamePrefix="react-select-multi"
              ></Select>
            </div>
            <div className="form-item">
              <label className="form-label">Units</label>
              <Select
                isMulti
                options={unitsOption}
                hideSelectedOptions={false}
                value={filters.units_ids}
                onChange={(e) => onFilter(e, 'units_ids')}
                className="form-control-select"
                classNamePrefix="react-select-multi"
              ></Select>
            </div>
            <div className="form-item">
              <label className="form-label">Date added</label>
              <DatePicker
                selected={startDate}
                className="form-control"
                onChange={handleDateChange}
                selectsRange
                startDate={startDate}
                endDate={endDate}
                isClearable
                disabledKeyboardNavigation
                placeholderText={'Select...'}
              ></DatePicker>
            </div>
            <div className="form-item">
              <label className="form-label">Status</label>
              <Select
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'active',
                    label: 'Active',
                  },
                  {
                    value: 'archived',
                    label: 'Inactive',
                  },
                ]}
                value={filters.status}
                onChange={(e) => onFilter(e, 'status')}
                className="form-control-select"
                classNamePrefix="react-select"
              ></Select>
            </div>
          </div>

          <button className="btn btn-outline" onClick={clearFilter}>
            Clear All
          </button>
        </div>
      )}

      {usersList?.length > 0 && (
        <div className="table-responsive table-default table-full">
          <div className="table-wrapper">
            <div className="table">
              <div className="tr">
                {column.map((item, index) => (
                  <div className="th" key={index}>
                    {item.name}
                    {item.tooltip && (
                      <>
                        <i data-tooltip-id={`table-tooltip-${index}`} className="icon-icon-info"></i>
                        <Tooltip opacity={1} id={`table-tooltip-${index}`} place="bottom" content={item.tooltip} />
                      </>
                    )}
                    {!!item.total && <span className="th-total">(Total: {item.total} users)</span>}
                  </div>
                ))}
              </div>
              {usersList.map((item) => (
                <div
                  className={`tr cursor-pointer ${item.deleted_at ? 'tr-deactivated' : ''}`}
                  key={item.id}
                  onClick={() => navigate(`/company/${id}/residents/${item.id}`)}
                >
                  <>
                    <div className="td">
                      <div>
                        {item.last_name} {item.first_name}
                        <span className="text-secondary d-flex">{item.email}</span>
                      </div>
                    </div>
                    <div className="td">{item.phone}</div>
                    <div className="td">{item.facility?.name}</div>
                    <div className="td">{item.unit?.name}</div>
                    <div className="td">{item.unit_bed?.bed}</div>
                  </>
                </div>
              ))}
            </div>
            {totalPage > 1 && (
              <ReactPaginate
                className={'react-pagination'}
                breakLabel="..."
                nextLabel="next ->"
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="<- previous"
                onPageChange={handlePageClick}
              />
            )}
          </div>
        </div>
      )}

      {!usersList?.length && !search && (
        <EmptyPage
          imageUrl={'/images/empty-user.png'}
          title={'Currently, there are no registered residents'}
          subtitle={'Simply click the button “Add Resident”  to initiate the resident addition process'}
        />
      )}
      {!usersList?.length && search && (
        <EmptyPage
          imageUrl={'/images/no-resoult.png'}
          title={'Unfortunately, nothing was found for your query.'}
          subtitle={"Try to change the range of keywords. We're here to help you find what you're looking for."}
        />
      )}
    </>
  );
}

export default UserTab;
